<template>
	<div class="package-info-bg" v-if="isShowInfo">
		<div class="alertWrap">
			<img :src="goodsIcon" class="icon">
			<div class="name">{{ goodsName }}</div>
			<div class="des">
				<div class="des-txt">
					{{ goodsDes }}
				</div>
			</div>
			<div class="price">
				<span v-if="priceType===1" class="money-icon">¥</span>
				<img v-else-if="priceType===2" src="../../images/pic_yuanbao.png" class="money-icon">
				<div class="price-txt">{{ goodsPrice }}</div>
			</div>
			<div class="bottom">
				<div class="btn btn-close" @click="closeInfo"></div>
				<div class="btn btn-buy" :class="[!canBuy?'':'btn-disabled']" @click="confirmInfo"></div>
			</div>
		</div>
	</div>
</template>

<script>
//index.js 的相对路径
import ShowInfo from "./ShowInfo.js";// 名字可以任取
export default ShowInfo;
</script>

<style lang="less">
@import "./ShowInfo.less";
</style>