<template>
	<div class="gm-banner">
		<div class="title">
			<img class="title-icon" src="../../images/gm-tittle.png"/>
			<span class="title-name">GM修改器</span>
		</div>
		<div class="user-info">
			<a class="nick">
				{{ userInfo.actorname }}(id:{{ userInfo.account_id }})
			</a>
			<a class="amount-label">余额：<a class="amount">¥{{ $utils.formatNums(userInfo.amount) }}</a></a>
			<a class="limit-label">每日使用额度：¥{{userInfo.cost_limit}}</a>
			<a class="used-label">已使用：¥{{userInfo.gm_used_today}}</a>
			<div class="limit-progress">
				<mt-progress :value="100*userInfo.gm_used_today/userInfo.cost_limit" :bar-height="15">
					<div slot="end" class="limit-label progress-end">¥{{
							$utils.formatNums(userInfo.cost_limit)
						}}
					</div>
				</mt-progress>
			</div>
		</div>
		<div class="tips">
			<div @click="showInfo()">?</div>
		</div>

		<div class="tips-show" v-if="isShow" >
			<div class="tips-back">
				<div>{{gmConfig.tips}}</div>
			</div>
			<span class="tips-close" @click="closeTip">
				点击关闭
			</span>
		</div>
	</div>

</template>

<script>
import GmBanner from "./GmBanner.js";// 名字可以任取
export default GmBanner;
</script>

<style lang="less">
@import "./GmBanner.less";
</style>