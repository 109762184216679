import axios from "axios";


export default {
	name: "GmBanner",
	props: {
		userInfo: Object | Array,
		gmConfig: Object | Array,
	},
	data: function () {
		return {
			contentHeight: 'auto',
			isActive: true,
			isShow: false
		}
	},
	mounted() {
		this.initHeight()
		window.addEventListener("resize", this.initHeight, false)
		this.gmConfig.tips = decodeURIComponent(this.gmConfig.tips)
		console.log(this.gmConfig)
	},
	methods: {
		initHeight: function () {
			let windowHeight = window.innerHeight
			let bannerHeight = document.getElementById('banner').offsetHeight;
			this.contentHeight = `${windowHeight - bannerHeight}px`;
			// console.log(this.contentHeight)
		},
		showInfo: function () {
			this.isShow = true;
		},
		closeTip:function () {
			this.isShow = false;
		}
	}
}