import {Indicator, Toast} from "mint-ui";
import axios from "axios";

export default {
	name: "GmExchange",
	props: {
		dataList: Object | Array,
		id: String,
		userInfo: Object | Array
	},
	data: function () {
		return {
			contentHeight: 'auto',
			isActive: true,
			cost_num: {}
		}
	},
	mounted() {
		this.initHeight()
		this.getInfoFromClient()
		window.addEventListener("resize", this.initHeight, false)
		console.log(this.dataList);
	},
	methods: {
		initHeight() {
			let windowHeight = window.innerHeight
			let bannerHeight = document.getElementById('banner').offsetHeight;
			this.contentHeight = `${windowHeight - bannerHeight}px`;
			// console.log(this.contentHeight)
		},
		reload() {
			this.isActive = false;
			this.$nextTick(() => {
				this.isActive = true;
			})
		},
		showInfo(index) {
			console.log(index)
			let goods = this.dataList.goods_data[index];
			let des = `使用 ${goods.cost_name} 兑换`;
			let goodsInfo = {
				showInfoGoodsId: index,
				showInfoGoodsIcon: goods.icon,
				showInfoGoodsName: goods.name,
				showInfoGoodsDes: des,
				showInfoGoodsPrice: goods.cost_num,
				showInfoPriceType: 3,
				showInfoConfirm: this.buyItem,
				showInfoCanBuy: goods.cost_num > this.cost_num[goods.cost]
			}
			this.$parent.showInfo(goodsInfo)
		},

		getInfoFromClient() {
			let id_list = this.dataList.goods_data.map(function (item) {
				return item.cost;
			});
			id_list = this.$utils.unique10(id_list);
			if (this.dataList.code_type == 0) {
				this.sendToHtml(this.dataList.code_str[0], id_list)
			}
		},
		buyItem(index) {
			let goods = this.dataList.goods_data[index];
			if (this.dataList.code_type == 0) {
				console.log(goods)
				console.log(this.id)
				let params = {
					goods_id: goods.id,
					cost_id: goods.cost
				}
				this.sendToHtml(this.dataList.code_str[1], params)
			}
		},
		getMessage(e) {
			console.log(e)
			if (this.dataList.code_type == 0) {
				if (e.method == this.dataList.code_str[0]) {
					this.cost_num = e.data
				}
				if (e.method == this.dataList.code_str[1]) {
					if (e.data.buyResult == 1) {
						this.cost_num[e.data.resId] = e.data.resBalance
						Toast('购买成功。');
					} else {
						Toast('购买失败。');
					}
				}
			}
		},
		sendToHtml(method, data) {

			let params = {
				id: this.id,
				method: method,
				data: data
			}
			console.log(params);
			this.$parent.sendMessage(params, this.getMessage, method)
		}
	},
}