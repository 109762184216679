<template>
	<div v-if="isActive">
		<div class="package-list" :style="`height:${contentHeight}`">
			<div :class="'lb-item lb-item-'+index" v-for="(item, index) in dataList">
				<div class="bg">
					<p class="title">{{ item.name }}</p>
					<p class="text text1">礼包内容含：</p>
					<p class="text text2">{{ item.desSr }}</p>
				</div>
				<div class="bottom">
					<div class="left">
						<div class="price">￥{{ item.price }}</div>
						<p class="limit">限购:{{ item.num }}</p>
					</div>
					<div class="buy" @click="showInfo(index)"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
//index.js 的相对路径
import PackageShop from "./PackageShop.js";// 名字可以任取
export default PackageShop;
</script>

<style lang="less">
@import "PackageShop.less";
</style>