<template>
	<div v-if="isActive" class="gm-shop">
		<!--		<div class="goods-class-bg" id="goods-class" ref="goodsClass">-->
		<!--			<div class="goods-class">-->
		<!--				<div class="goods-class-item" :class="current_index==index_class?'active':''"-->
		<!--					 v-for="(goods_data, index_class) in dataList" @click="changeClass(index_class)">-->
		<!--					<img :src="goods_data.icon" class="class-icon">-->
		<!--					<img src="../../images/dividing.png" class="class-right" v-if="index_class<(dataList.length-1)">-->
		<!--				</div>-->
		<!--			</div>-->
		<!--			<img src="../../images/btn_more.png" class="more-icon">-->
		<!--		</div>-->

		<div class="goods-content" v-if="userInfo.enabled[0]" id="goods-content" :style="`height: ${contentHeight}`">
			<!--			<div class="yuanbaolist" v-for="(goods_data, index_class) in dataList" v-if="current_index==index_class">-->

			<div class="ybitem" v-for="(item, index) in dataList.goods_data">
				<div class="left">
					<div class="icon-and-count">
						<img :src="[$global.imgBaseUrl+item.icon]" class="icon" @click="showInfo(index)">
						<span class="goods-count">{{ item.num }}</span>
					</div>
					<div class="content">
						<p class="name">{{ item.name }}</p>
						<div class="price">
<!--							<img src="../../images/pic_yuanbao.png" class="yuanbao-icon">-->
							<span >¥</span>
							<span>{{ $utils.formatNums(item.price) }}</span>
						</div>
					</div>
				</div>
				<div class="right">
					<p class="limit" v-if="item.limit_type==1">
						剩余:{{ item.num - (userInfo.limit_info[item.id] ? userInfo.limit_info[item.id] : 0) }}
					</p>
					<p class="limit" v-else-if="item.limit_type==2">
						剩余:{{ item.num - (userInfo.limit_info[item.id] ? userInfo.limit_info[item.id] : 0) }}
					</p>
					<p class="limit" v-else>
						剩余:&infin;
					</p>
					<div class="buy" @click="showInfo(index)"></div>
				</div>
			</div>
		</div>
		<div class="disabled-content" v-else>
			<h6 v-if="userInfo.enabled.length == 3">再充值{{userInfo.enabled[2]-userInfo.enabled[1]}}元可激活。</h6>
			<h6 v-else>{{userInfo.enabled[1]}}</h6>
		</div>
	</div>
	<!--	</div>-->
</template>

<script>
import GmShop from "./GmShop.js";// 名字可以任取
export default GmShop;
</script>

<style lang="less">
@import "./GmShop.less";
</style>