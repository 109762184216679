import PackageShop from '../components/packageShop/PackageShop.vue';
import MoneyShop from "../components/moneyShop/MoneyShop.vue";
import ShowInfo from "../components/showInfo/ShowInfo.vue";

import GmShop from "../components/gmShop/GmShop.vue";
import GmBanner from "../components/gmBanner/GmBanner.vue";
import GmExchange from "../components/gmExchange/GmExchange.vue";
import GmCard from "../components/gmCard/GmCard.vue";
import GmCharge from "../components/gmCharge/GmCharge.vue";

import axios from 'axios';
import {Indicator, Toast, MessageBox} from "mint-ui";


placeholderPic();

function placeholderPic() {
	var w = document.documentElement.offsetWidth;
	if (isMobile()) {
		document.documentElement.style.fontSize = w / 7.5 + 'px';
	}
}

function isMobile() {
	let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
	return flag;
}

window.onresize = function () {
	placeholderPic();
}

window.addEventListener("focusout", () => {
	window.scrollTo(0, 0);
});

export default {
	name: 'App',
	components: {
		PackageShop,
		MoneyShop,
		ShowInfo,
		GmShop,
		GmBanner,
		GmExchange,
		GmCard,
		GmCharge
	},
	data: function () {
		return {
			// banner
			game: '',
			publisher: '',
			server_id: "",
			role_id: "",
			isShowInfo: false,
			showInfoConfirm: null,
			showInfoGoodsId: null,
			showInfoGoodsIcon: null,
			showInfoGoodsName: null,
			showInfoGoodsDes: null,
			showInfoGoodsPrice: null,
			showInfoPriceType: null,
			goodsInfo: null,

			banner: require("../assets/bg_chaozhishangcheng_bg.png"),
			nowIndex: 0,
			activityList: [],
			user_info: null,
			gmConfig: null,
			callback: {},
			method: ''
		}
	},
	created() {
		this.$global.baseUrl = `${this.$utils.getProtocol()}://${this.$global.baseUrl}`;
		this.$global.imgBaseUrl = `${this.$utils.getProtocol()}://${this.$global.imgBaseUrl}`;

		this.getInfo();
	},
	mounted() {
		window.addEventListener("resize", this.watch, false);
		this.watch();


		window.addEventListener("message", this.getMessage, false);

	},
	methods: {
		/**
		 * 获取活动列表
		 */
		getInfo() {
			let _this = this;
			Indicator.open({
				spinnerType: 'snake'
			});
			this.game = this.$utils.getUrlKey('game', '逍遥游');
			this.publisher = this.$utils.getUrlKey('publisher', '远游四发');
			this.server_id = this.$utils.getUrlKey('server_id', '');
			this.role_id = this.$utils.getUrlKey('role_id', '');
			axios.get(`${this.$global.baseUrl}index.html?game=${this.game}&publisher=${this.publisher}&server_id=${this.server_id}&role_id=${this.role_id}`)
				.then(function (response) {
					Indicator.close();
					if (response.data.code == 200) {
						_this.activityList = response.data.data
						_this.user_info = response.data.user_info;
						_this.gmConfig = response.data.gmConfig;
					} else {
						// Toast(response.data.msg);
						MessageBox('提示', response.data.msg);
					}
				})
		},
		watch() {
			// 判断横竖屏
			let width = document.documentElement.clientWidth;
			let height = document.documentElement.clientHeight;
			if (width > height) {
				this.banner = require("../assets/bg_chaozhishangcheng_2_bg.png")
			} else {
				this.banner = require("../assets/bg_chaozhishangcheng_bg.png")
			}
		},
		tabClick(index) {
			this.nowIndex = index
		},
		// 下拉刷新
		touchStart(e) {
			this.startY = e.targetTouches[0].pageY
			this.startScroll = this.$el.scrollTop || 0
		},
		touchMove(e) {
			let diff = e.targetTouches[0].pageY - this.startY - this.startScroll
			// if (diff > 0) e.preventDefault()
			this.top = Math.pow(diff, 0.8) + (this.state === 2 ? this.offset : 0)
			if (this.state === 2) { // in refreshing
				this.refresh()
				// return
			}
			if (this.top >= this.offset) {
				this.state = 1
			} else {
				this.state = 0
			}
		},
		// eslint-disable-next-line no-unused-vars
		touchEnd(e) {
			if (!this.enableRefresh) return
			if (this.state === 2) { // in refreshing
				this.state = 2
				// this.top = this.offset
				this.top = 0
				return
			}
			if (this.top >= this.offset) { // do refresh
				this.refresh()
			} else { // cancel refresh
				this.state = 0
				this.top = 0
			}
		},
		refresh() {
			this.state = 2
			this.top = 0
		},
		showInfo(goods) {
			this.isShowInfo = true;
			this.goodsInfo = goods;
		},
		refreshAmount(amount) {
			this.user_info.amount = amount;
		},
		sendMessage(e, callback, method) {
			Indicator.open({
				spinnerType: 'snake'
			});
			console.log('click')
			console.log(e)
			this.callback[method + e.id] = callback;
			this.method = method;
			window.parent.postMessage(e, '*');
		},
		getMessage(e) {
			if (e.data.hasOwnProperty('method')) {
				console.log(e.data.method)
				console.log(this.method)
				if (e.data.method === this.method && this.callback.hasOwnProperty(e.data.method + e.data.id)) {
					let callbackMethod = this.callback[e.data.method + e.data.id]
					callbackMethod(e.data)
					Indicator.close();
				}
			}
		}
	},
	destroyed() {
		// 在组件生命周期结束的时候销毁。
		window.removeEventListener("message", this.getMessage);
	},
}