<template>
	<div id="app" class="page">

		<div class="content">
			<!-- banner -->

			<div class="banner" id="banner">
				<img :src="banner" class="banner-pic" >
				<template v-if="user_info">
					<GmBanner :user-info="user_info" :gm-config="gmConfig"/>
				</template>
			</div>
			<!-- tabs -->
			<div class="navlist" id="navlist">
				<!-- <div class="navli activeT">
					元宝商城
				  </div> -->


				<div class="navli" v-for="(item, index) in activityList" :class="{'activeT':nowIndex===index}"
					 @click="tabClick(index)">
					{{ item.name }}
				</div>
			</div>
			<div class="list-wrap" id="list-wrap">
				<div :class="['list-slide', {'activeT':nowIndex===index}]" v-for="(item, index) in activityList">
					<!-- 元宝商城 -->
					<template v-if="item.act_id==='元宝商城'">
						<MoneyShop :dataList="item.data" :id="item.id" :user-info="item.userInfo"/>
					</template>
					<!-- 礼包商城 -->
					<template v-if="item.act_id==='礼包商城'">
						<PackageShop :dataList="item.data" :id="item.id"/>
					</template>
					<!-- GMN商城 -->
					<template v-if="item.act_id==='GM商城'">
						<GmShop :dataList="item.data" :id="item.id" :user-info="item.userInfo"/>
					</template>

					<template v-if="item.act_id==='GM兑换'">
						<GmExchange :dataList="item.data" :id="item.id" :user-info="item.userInfo"/>
					</template>
					<template v-if="item.act_id==='GM福利卡'">
						<GmCard :dataList="item.data" :id="item.id" :user-info="item.userInfo"/>
					</template>
					<template v-if="item.act_id==='GM充值'">
						<GmCharge :dataList="item.data" :id="item.id" :user-info="item.userInfo"/>
					</template>
				</div>

				<template>
					<ShowInfo :isShowInfo="isShowInfo" :goodsInfo="goodsInfo"/>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
//index.js 的相对路径
import App from "./App.js";

export default App;
</script>

<style lang="less">
@import "./App.less";
</style>