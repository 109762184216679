import {Indicator, Toast} from "mint-ui";
import axios from "axios";

export default {
	name: "CardInfo",
	props: {
		goodsInfo: Object | Array,
		isShow: Boolean,
	},
	watch: {
		goodsInfo: function () {
			this.initInfo()
		}
	},
	data: function () {
		return {
		}
	},
	mounted() {
		console.log(this.dataList);
	},
	methods: {
		initInfo(){

		},
		closeInfo() {
			this.$parent.$data.isShowInfo = false
		},
		confirmInfo() {
			if (this.goodsInfo.showInfoConfirm) {
				this.goodsInfo.showInfoConfirm(this.goodsInfo.id);
			}
			this.closeInfo();
		}
	},
}