import {Indicator, Toast} from "mint-ui";
import axios from "axios";


import CardInfo from "./CardInfo.vue";

export default {
	name: "GmCard",
	props: {
		dataList: Object | Array,
		id: String,
		userInfo: Object | Array
	},
	components: {
		CardInfo
	},
	data: function () {
		return {
			contentHeight: 'auto',
			isActive: true,
			isShowInfo: false,
			goodsInfo: null
		}
	},
	mounted() {
		this.initHeight()
		window.addEventListener("resize", this.initHeight, false)
		console.log(this.dataList);
	},
	methods: {
		initHeight() {
			let windowHeight = window.innerHeight
			let bannerHeight = document.getElementById('banner').offsetHeight;
			this.contentHeight = `${windowHeight - bannerHeight}px`;
			// console.log(this.contentHeight)
		},
		reload() {
			this.isActive = false;
			this.$nextTick(() => {
				this.isActive = true;
			})
		},
		showInfo(index) {
			console.log(index)
			this.goodsInfo = this.dataList.goods_data[index];
			this.goodsInfo.status = this.userInfo.limit_info[this.goodsInfo.id] ? this.userInfo.limit_info[this.goodsInfo.id]>0 : false
			this.goodsInfo.showInfoConfirm = this.buyItem
			console.log(this.goodsInfo)
			this.isShowInfo = true;

		},

		receive(goods) {
			console.log(goods)
			this.isShowInfo = false;
			if (this.dataList.code_type == 0) {
				let params = goods.id;
			}

		},
		buyItem(goodsId) {
			let _this = this
			Indicator.open({
				spinnerType: 'snake'
			});
			let parentData = this.$parent.$data;
			let param = new FormData();

			param.append("game", parentData.game);
			param.append("publisher", parentData.publisher);
			param.append("server_id", parentData.server_id);
			param.append("role_id", parentData.role_id);
			param.append("plan_id", this.id);
			param.append("id", goodsId);

			axios.post(
				`${this.$global.baseUrl}send-goods.html`,
				param
			).then(function (response) {
				Indicator.close();
				let data = response.data;
				if (data.code > 0) {
					if (_this.userInfo.limit_info[goodsId]) {
						_this.userInfo.limit_info[goodsId] = _this.userInfo.limit_info[goodsId] + 1;
					} else {
						_this.userInfo.limit_info[goodsId] = 1
					}
					_this.$parent.getInfo()
					_this.reload()
					Toast('购买成功。');
				} else {
					console.log(data.msg);
					Toast(data.msg);
				}
			}).catch(function (error) {
				console.log(error);
				Indicator.close();
				Toast(error);
			});

			setTimeout(() => {
				Indicator.close();
			}, 2000);
		},
		getMessage(e) {
			console.log(e)
			if (this.dataList.code_type == 0) {
				if (e.method == this.dataList.code_str[0]) {
					this.card_info = e.data
				}
				if (e.method == this.dataList.code_str[1]) {
					if (e.data.result == 1) {
						this.card_info[e.data.id] = e.data.status
						Toast('领取成功。');
					} else {
						Toast('领取失败。');
					}
				}
				if (e.method == this.dataList.code_str[2]) {
					if (e.data.result == 1) {
						this.card_info[e.data.id] = e.data.status
						Toast('购买成功。');
					} else {
						Toast('购买失败。');
					}
				}
			}
		},
		sendToHtml(method, data) {

			let params = {
				id: this.id,
				method: method,
				data: data
			}
			console.log(params);
			this.$parent.sendMessage(params, this.getMessage, method)
		}
	},
}