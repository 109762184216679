<script>
export default {
	getUrlKey: function (name, defaultValue) {
		defaultValue = typeof defaultValue !== 'undefined' ? defaultValue : null;
		return decodeURIComponent(
			(new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || defaultValue;
	},

	formatNums: function (num) {
		num = Number(num);
		var new_num = num;
		if (num > 9999) {
			new_num = "" + Math.floor(num / 100) / 100 + '万'
		}
		// if (num > 999999) {
		// 	new_num = "" + Math.floor(num / 100000) / 10 + '百万'
		// }
		return new_num;
	},

	getProtocol:function() {
		var ishttps = document.location.protocol.indexOf("https:")!= -1 ? true: false;
		if(ishttps){
			return 'https';
		}else{
			return'http';
		}
	},

	payment: function ($params) {

	},
	//Set去重
	unique10: function (arr) {
		//Set数据结构，它类似于数组，其成员的值都是唯一的
		return Array.from(new Set(arr)); // 利用Array.from将Set结构转换成数组
	}
}
</script>