<template>
	<div v-if="isActive">
		<div class="goods-class-bg" id="goods-class" ref="goodsClass">
			<div class="goods-class">
				<div class="goods-class-item" :class="current_index==index_class?'active':''"
					 v-for="(goods_data, index_class) in dataList" @click="changeClass(index_class)">
					<img :src="goods_data.icon" class="class-icon">
					<img src="../../images/dividing.png" class="class-right" v-if="index_class<(dataList.length-1)">
				</div>
			</div>
			<img src="../../images/btn_more.png" class="more-icon">
		</div>
		<div class="goods-content" id="goods-content" :style="`height: ${contentHeight}`">
			<div class="yuanbaolist" v-for="(goods_data, index_class) in dataList" v-if="current_index==index_class">
				<div class="ybitem" v-for="(item, index) in goods_data.list">
					<div class="left">
						<div class="icon-and-count">
							<img :src="item.icon" class="icon">
							<span class="goods-count">{{ item.item_count }}</span>
						</div>
						<div class="content">
							<p class="name">{{ item.name }}</p>
							<div class="price">
								<img src="../../images/pic_yuanbao.png" class="yuanbao-icon">
								<span>{{ $utils.formatNums(item.price) }}</span>
							</div>
						</div>
					</div>
					<div class="right">
						<p class="limit">限购:{{ item.num - (userInfo[item.id] ? userInfo[item.id] : 0) }}</p>
						<div class="buy" @click="showInfo(index)"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MoneyShop from "./MoneyShop.js";// 名字可以任取
export default MoneyShop;
</script>

<style lang="less">
@import "./MoneyShop.less";
</style>