import {Indicator} from "mint-ui";

export default {
	name: "PackageShop",
	props: {
		dataList: Array,
		id: String
	},
	data: function () {
		return {
			packageInfo: {},
			fullscreenLoading: false,
			contentHeight: 'auto',
			isShowInfo: false,
			isActive: true
		}
	},
	mounted() {
		this.initHeight()
		window.addEventListener("resize", this.initHeight, false)
	},
	methods: {
		initHeight: function () {
			let windowHeight = window.innerHeight
			let bannerHeight = document.getElementById('banner').offsetHeight;
			this.contentHeight = `${windowHeight - bannerHeight}px`;
			// console.log(this.contentHeight)
		},
		reload() {
			this.isActive = false;
			this.$nextTick(() => {
				this.isActive = true;
			})
		},
		buyItem: function (goodsId) {
			console.log(goodsId);
			// 礼包商城购买按钮
			let _this = this
			Indicator.open({
				spinnerType: 'snake'
			});

			window.parent.postMessage({refresh: 'id'}, '*');
		},
		showInfo: function (index) {
			let goods = this.dataList[index];
			let goodsInfo = {
				showInfoGoodsId: goods.id,
				showInfoGoodsIcon: goods.icon,
				showInfoGoodsName: goods.name,
				showInfoGoodsDes: goods.desSr,
				showInfoGoodsPrice: goods.price,
				showInfoPriceType: 1,
				showInfoConfirm: this.buyItem
			}
			this.$parent.showInfo(goodsInfo)
		}
	}
}