import Vue from 'vue'
import utils from './components/Util';
import Global from "./components/Global";
import 'mint-ui/lib/style.css';
import App from './view/App.vue';

import {Progress} from 'mint-ui';

Vue.component(Progress.name, Progress);

Vue.prototype.$utils = utils;
Vue.prototype.$global = Global;

Vue.config.productionTip = false

// document.title='商城';

new Vue({
	htmlWebpackPlugin: {
		options: {
			title: 'test'
		}
	},
	render: h => h(App),
}).$mount('#app')

