import {Indicator, Toast} from "mint-ui";
import axios from "axios";

export default {
	name: "GmShop",
	props: {
		dataList: Object | Array,
		id: String,
		userInfo: Object | Array
	},
	data: function () {
		return {
			contentHeight: 'auto',
			isActive:true
		}
	},
	mounted() {
		this.initHeight()
		window.addEventListener("resize", this.initHeight, false)
		console.log(this.dataList)
	},
	methods: {
		initHeight: function () {
			let windowHeight = window.innerHeight
			let bannerHeight = document.getElementById('banner').offsetHeight;
			this.contentHeight = `${windowHeight - bannerHeight}px`;
			// console.log(this.contentHeight)
		},
		reload() {
			this.isActive=false;
			this.$nextTick(()=>{
				this.isActive=true;
			})
		},
		showInfo(index) {
			let goods = this.dataList.goods_data[index];
			let goodsInfo = {
				showInfoGoodsId: goods.id,
				showInfoGoodsIcon: goods.icon,
				showInfoGoodsName: goods.name,
				showInfoGoodsDes: `获得：${goods.desSr}`,
				showInfoGoodsPrice: goods.price,
				showInfoPriceType: 1,
				showInfoConfirm: this.buyItem
			}
			this.$parent.showInfo(goodsInfo)
		},
		buyItem(goodsId) {
			let _this = this
			Indicator.open({
				spinnerType: 'snake'
			});
			let parentData = this.$parent.$data;
			let param = new FormData();

			param.append("game", parentData.game);
			param.append("publisher", parentData.publisher);
			param.append("server_id", parentData.server_id);
			param.append("role_id", parentData.role_id);
			param.append("plan_id", this.id);
			param.append("id", goodsId);

			axios.post(
				`${this.$global.baseUrl}send-goods.html`,
				param
			).then(function (response) {
				Indicator.close();
				let data = response.data;
				if (data.code > 0) {
					if (_this.userInfo.limit_info[goodsId]) {
						_this.userInfo.limit_info[goodsId] = _this.userInfo.limit_info[goodsId] + 1;
					} else {
						_this.userInfo.limit_info[goodsId] = 1
					}
					console.log(data.amount);
					_this.$parent.getInfo()
					_this.reload()
					Toast('购买成功，物品已发送到邮箱。');
				} else {
					console.log(data.msg);
					Toast(data.msg);
				}
			}).catch(function (error) {
				console.log(error);
				Indicator.close();
				Toast(error);
			});

			setTimeout(() => {
				Indicator.close();
			}, 2000);
		}
	}
}