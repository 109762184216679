<template>
	<div v-if="isActive" class="gm-exchange">
		<div class="goods-content" id="goods-content" :style="`height: ${contentHeight}`">
			<div class="ybitem" v-for="(item, index) in dataList.goods_data">
				<div class="left">
					<div class="icon-and-count">
						<img :src="[$global.imgBaseUrl+item.icon]" class="icon" @click="showInfo(index)">
						<span class="goods-count">{{ item.num }}</span>
					</div>
					<div class="content">
						<p class="name">{{ item.name }}</p>
						<div class="price">
							<!--							<img src="../../images/pic_yuanbao.png" class="yuanbao-icon">-->
							<span>{{ item.cost_name }}:</span>
							<span
								:class="[item.cost_num<cost_num[item.cost]?'enough':'deficient']">
								{{ item.cost_num }}/{{ $utils.formatNums(cost_num[item.cost]) }}
							</span>
						</div>
					</div>
				</div>
				<div class="right">
					<p class="limit" v-if="item.limit_num>0">
						限购:{{ item.limit_num }}
					</p>
					<div v-if="item.cost_num<cost_num[item.cost]" class="buy" @click="showInfo(index)"></div>
					<div v-else class="buy buy-disable"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import GmExchange from "./GmExchange.js";// 名字可以任取
export default GmExchange;
</script>

<style lang="less">
@import "./GmExchange.less";
</style>