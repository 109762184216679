<template>
	<div v-if="isActive" class="gm-card">
		<div class="goods-content" id="goods-content" :style="`height: ${contentHeight}`">
			<div class="ybitem" v-for="(item, index) in dataList.goods_data">
				<div class="left">
					<div class="icon-and-count">
						<img :src="[$global.imgBaseUrl+item.icon]" class="icon" @click="showInfo(index)" alt="">
						<span class="goods-count">{{ item.num }}</span>
					</div>
					<div class="content">
						<p class="name" @click="showInfo(index)">{{ item.name }}</p>
						<div class="price">
							<!--							<img src="../../images/pic_yuanbao.png" class="yuanbao-icon">-->

						</div>
					</div>
				</div>
				<div class="right">
					<div v-if="userInfo.limit_info[item.id] ? userInfo.limit_info[item.id]>0 : false">
						<label @click="showInfo(index)">已激活</label>
					</div>
					<!--					<div v-else-if="card_info[item.id]==0">-->
					<!--						<div class="buy" v-if="item.config.length>0"-->
					<!--							 @click="showInfo(index)">激活-->
					<!--						</div>-->
					<!--						<div v-else><label>未激活</label></div>-->
					<!--					</div>-->
					<div v-else>
						<div class="buy" @click="showInfo(index)"></div>
					</div>
				</div>
			</div>
		</div>

		<template>
			<CardInfo :is-show="isShowInfo" :goods-info="goodsInfo"/>
		</template>
	</div>
</template>

<script>
import GmCard from "./GmCard.js";

export default GmCard;
</script>

<style lang="less">
@import "./GmCard.less";
</style>