<template>
	<div class="package-info-bg" v-if="isShow">
		<div class="tips-back">
			<img class="icon" alt="" :src="[$global.imgBaseUrl+goodsInfo.icon]">
			<div class="title">
				{{ goodsInfo.name }}
			</div>
			<div class="des" v-html="decodeURIComponent(goodsInfo.des)">
			</div>
			<div class="bottom">

				<div class="btn btn-close" @click="closeInfo"></div>
<!--				<div v-if="goodsInfo.status">-->
<!--					已激活-->
<!--				</div>-->
				<div v-if="!goodsInfo.status" class="btn btn-buy" @click="confirmInfo"></div>
			</div>
		</div>
		<!--		<div class="tips-close" @click="closeInfo">-->
		<!--			点击关闭-->
		<!--		</div>-->
	</div>
</template>

<script>
import CardInfo from "./CardInfo.js";

export default CardInfo;
</script>

<style lang="less">
@import "./CardInfo.less";
</style>