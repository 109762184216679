export default {
	name: "ShowInfo",
	props: {
		isShowInfo: Boolean | null,
		goodsInfo: Object | null
	},
	watch: {
		goodsInfo: function () {
			this.initInfo()
		}
	},
	data() {
		return {
			goodsId: Number | null,
			goodsIcon: String | null,
			goodsName: String | null,
			goodsDes: String | null,
			goodsPrice: String | null,
			priceType: Number | null,
			confirm: Function | null,
			canBuy: Boolean | true,
		}
	},
	methods: {
		initInfo() {
			this.goodsId = this.goodsInfo.showInfoGoodsId
			this.goodsIcon = this.$global.imgBaseUrl + this.goodsInfo.showInfoGoodsIcon
			this.goodsName = this.goodsInfo.showInfoGoodsName
			this.goodsDes = this.goodsInfo.showInfoGoodsDes
			this.goodsPrice = this.goodsInfo.showInfoGoodsPrice
			this.priceType = this.goodsInfo.showInfoPriceType
			this.confirm = this.goodsInfo.showInfoConfirm
			this.canBuy = this.goodsInfo.showInfoCanBuy
		},
		closeInfo() {
			this.$parent.$data.isShowInfo = false
		},
		confirmInfo() {
			if (this.confirm) {
				this.confirm(this.goodsId);
			}
			this.closeInfo();
		}
	}
}